<template lang="pug">
.main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Estabelecimentos</b>

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/estabelecimentos/salvar/0/')")

        Dialog.dialogApagar(header='Remover Estabelecimento' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a unidade <b>{{ dialogApagar_data.nm_unidade }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-4
                    label.form-label Estabelecimento:
                    .p-inputgroup
                        InputText(placeholder='Estabelecimento'
                            @keyup.enter.native='applyFilters()'
                            @input='checkEmpty_nm_fantasia()'
                            v-model='filters.nm_fantasia'
                            v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-2
                    label.form-label Estado:
                    Dropdown(v-model='filters.cd_uf' :options='options.estados' @change=' applyFilters()'
                        optionLabel='textAlt' optionValue='value' placeholder='Selecione' filter)
                .p-col-12.p-md-2
                    label.form-label Município:
                    Dropdown(v-model='filters.cd_cidade' :options='options.cidades'
                        optionLabel='text' optionValue='value' placeholder='Selecione' @change="applyFilters()" filter)
                .p-col-12.p-md-2
                    label.form-label Ativos:
                    Dropdown(v-model='filters.credenciados' :options='options.credenciados'
                        optionLabel='text' optionValue='value' placeholder='Selecione' @change="applyFilters()" filter)
                .p-col-12.p-md-2
                    label.form-label Grupos:
                    Dropdown(v-model='filters.ie_grupo' :options='options.ie_grupo'
                        optionLabel='text' optionValue='value' placeholder='Selecione' @change="applyFilters()" filter)
            .ta-right(style = "margin-top: 10px; ")
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()' style = "width: 32.4%;")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)         
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style='position: relative')
                            .ta-left
                                p <b>Nome Fantasia:</b> {{ props.data.nm_fantasia }}
            .p-grid.p-col-12.pr-0
                .p-col-4.ta-center.mt-1.pr-0
                    p.text-quantidades.ma-0 <b>Total de Estabelecimentos: {{total}}</b> 
                .p-col-4.ta-center.mt-1.pr-0
                    p.text-quantidades.ma-0.ml-2 <b>Ativos: {{total_ativos}}</b> 
                .p-col-4.ta-center.mt-1.pr-0
                    p.text-quantidades.ma-0.ml-2 <b>Inativos: {{total_inativos}}</b> 

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
        
            Panel.datatable(header='Lista de Estabelecimentos')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list")

                    Column(headerStyle='width: 30%;' field='nm_fantasia' header='Nome Fantasia')
                        template(#body='props')
                            span(v-tooltip.top="props.data.nm_fantasia" style='cursor: pointer;') {{ props.data.nm_fantasia }}

                    Column(headerStyle='width: 30%;' field='nm_razao_social' header='Razão social')
                        template(#body='props')
                            span(v-tooltip.top="props.data.nm_fantasia" style='cursor: pointer;') {{ props.data.nm_razao_social }}

                    Column(headerStyle='width: 20%;' field='nr_cnpj' header='CNPJ')
                        template(#body='props')
                            span(v-tooltip.top="props.data.nr_cnpj" style='cursor: pointer;') {{ props.data.nr_cnpj_f }}

                    Column(headerStyle='width: 20%;' field='ds_cidade' header='Cidade')
                        template(#body='props')
                            span(v-tooltip.top="props.data.nr_cnpj" style='cursor: pointer;') {{ props.data.ds_cidade }}

                    Column(headerStyle='width: 20%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Procedimentos'"
                                    icon="jam jam-medical"
                                    @click="goToProcedimentos(props.data.id)"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/estabelecimentos/salvar/${ props.data.id }/`)"
                                )
                                //- Button.p-button-raised.p-button-rounded.p-button-danger(
                                //-     v-tooltip.top="'Apagar'"
                                //-     icon="jam jam-minus-circle"
                                //-     @click="dialogApagar = true; dialogApagar_data = props.data"
                                //- )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .text-quantidades {
        margin-bottom: 4px;
        margin-right: 10px;
        display: inline-block;
        background-color: #faf3dd;
        box-shadow: 0 1px 2px #ddd;
        padding: 6px 10px;
    }
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import Dropdown from 'primevue/dropdown'

    import { Estabelecimento, Utils, DominioValor } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
                if (vm.$store.state[vm.$route.name] )
                    vm.filters = vm.$store.state[vm.$route.name]
                vm.applyFilters()
				vm.$store.commit('clearFilters')
			})
		},
		beforeRouteLeave (to, from, next) {
            this.$store.state[from.name] = this.filters
			next()
		},
        created () {
            Utils.getUFs().then(response => {
                this.options.estados = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                if (response.status === 200) response.data.forEach(uf => {
                    this.options.estados.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                })
            })
            DominioValor.find('GRUPO_ESTABELECIMENTOS').then(response => {
                if (!response) return
                if (([200,201,204]).includes(response.status)) {
                    this.options.ie_grupo =
                        [{ value: null, text: "Todos" }, ...response.data.filter(item => item.ie_status).map(item => ({ value: item.ie_valor, text: item.ds_valor }))]
                }
                this.waiting = false
                this.cancelToken = null
            })
        },
        watch: {
            'filters.cd_uf': function() {
                this.applyFilters()
                this.getCidades()
            },
            'filters.cd_cidade': function() {
                this.applyFilters()
            }
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                total: 0,
                total_ativos: 0,
                total_inativos: 0,
                filters: {
					nm_fantasia: '',
                    cd_uf: null,
                    cd_cidade: null,
                    credenciados: '',
                    ie_grupo: null
				},
                options: {
                    cidades: [],
                    estados:[],
                    ie_grupo: [],
                    credenciados:[
                        { value: '', text: 'Todos', textAlt: '- Todos -' },
                        { value: 1, text: 'Sim', textAlt: '- Sim -' },
                        { value: 0, text: 'Não', textAlt: '- Não -' }
                        
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return Estabelecimento.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        response.data.results.forEach(estabelecimento => {
                            if (estabelecimento.nr_cnpj) estabelecimento.nr_cnpj_f = estabelecimento.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                        })
                        this.list = response.data.results
                        this.total = response.data.count
                        this.total_ativos = response.data.total_ativos
                        this.total_inativos = response.data.total_inativos
                    }
                    this.waiting = false
                    return true
                })
            },
            resetFilters() {
				this.nm_fantasia = '',
                this.cd_uf = null,
                this.cd_cidade = null
                this.options.cidades = [],
                this.options.estados = []
			},
            limparFiltros() {
				Object.keys(this.filters).forEach(k => {
                    this.filters[k] = null
                })
                this.applyFilters()
			},
            checkEmpty_nm_fantasia () {
                if (! this.filters.nm_fantasia) this.applyFilters()
            },
            applyFilters () {
                let params = {
                    paginacao: true,
                    page: this.paginator.page,
                    per_page: this.paginator.per_page,
                    credenciados: this.$data.filters.credenciados
                }

                if (this.filters.credenciados !== null) {
                    params.credenciados = this.filters.credenciados
                }
                
                if (this.$route.query.pg != this.paginator.page) this.$router.replace({ query: { pg: this.paginator.page } })
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            goToProcedimentos (cd_estabelecimento) { // eslint-disable-line
                this.$store.commit('setFilters', { cd_estabelecimento })
                this.$router.push(`/procedimentos-estabelecimento/listar?cd_estabelecimento=${ cd_estabelecimento }`)
            },
            remove () {
                this.waitingApagar = true
                Estabelecimento.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Estabelecimento removida com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            getCidades() {
                this.filters.cd_cidade = null
                if(this.filters.cd_uf) {
                    Utils.getMunicipiosEstadoId(this.filters.cd_uf).then(response => {
                        if(([200,201]).includes(response.status)){
                            this.options.cidades = [
                                { value: null, text: '- Selecione -' },
                                ...response.data.map(item => ({ value: item.id, text: item.nm_cidade }))
                            ]
                        }
                    })
                }
            },
        }
    }
</script>
